@import '../../sass/global/variables';

.kpiprogress-container{ //removed id because it generates div containers with the same in ID in the DOM.
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
    position: relative;
    width: 100%;
    height: 55px;


    .kpi-progress-remaining{
      //  width: 100%;

      }

    .label-left{
        position: absolute;
        height: 30px;
        border-radius: 5px;
        background-color: gray;
        z-index: 1000;
        left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 1.8em;
        font-weight: normal;
        top: 13px;
    }
    .left-value{
        width: auto;
        text-align: center;
        padding: 0.5em;
    }
    .right-value{
        width: auto;
        text-align: center;
        padding: 0.5em;
    }
    .label-right{
        position: absolute;
        height: 30px;
        border-radius: 5px;
        background-color: gray;
        z-index: 1000;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 1.8em;
        font-weight: normal;
        top: 13px;
    }


}

[data-goal] {
  position: relative;
  display: flex;
  justify-content: flex-start;
  height: 55px;


  .kpi-progress-inner {
    width: 100%;

    height: 55px;
    background:transparent;
    overflow: hidden;
    position: relative;
  }

  .kpi-progress-fill {
    height: 55px;
    position: relative;
    z-index: 1000;

    &:after {
      display: block;
      content: " ";
      background-color: inherit;
      transform: skewX(13deg);
      transform-origin: top right;
      height: 55px;
      width: 2em;
      position: absolute;
      top: 0;
      right: 0;
      //z-index: 1000;
    }

  }



  //.kpi-progress-active{
  //    transform-origin: top right;
  //    height: 55px;
//  }

  //.active-skew{
  //  position: absolute;
  //    z-index: 1000;
  //    height: 55px;
  //    top: 0;
//    left: 0;

//  }
}

[data-goal]:after {
    content:" ";
    width: 1.5em;
    height: 5.5em;
    position: absolute;
    z-index: 1000;
    right: -1.5em;
    top:0;

    .thisyear & {
      background:linear-gradient(90deg,#FFF 50%,#000 0)0/5em,linear-gradient(#FFF 50%,#000 0)0/9% 5em;
      background-blend-mode:exclusion;
      background-size: 1.3em 1.3em;
    }

    .lastyear & {
      background:linear-gradient(90deg,#474646 50%,#767475 0)0/5em,
      linear-gradient(#474646 50%,#767475 0)0/9% 5em;
      background-blend-mode: difference;
      background-size: 1.3em 1.3em;
    }


  }



@media (min-width: 1024px) {
    .kpiprogress-container{
        //width: 90%;
    }

}
