@import 'src/theme/styles/variables';

header.fullcard-header {
  // background-color: transparent;
  background-color: #ffffff;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  min-height: 9vh;
	width: 100%;
  color: $icon-color;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1000;

  .header-content {
  	display: flex;
  	flex-direction: row;
  	justify-content: space-around;
  	align-items: stretch;
  	width: 100%;
    // padding: 0.7rem 0.3rem;
    height: 100%;

    > a {
      color: $icon-color;
      text-decoration: none;
    }

  }
}

.header-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%;
  max-width:4em;
  font-size: calc(#{$f-8} + 2vmin);
  color: $icon-color;
  text-transform: uppercase;
  // margin: 0.7rem;

  &:hover {
    cursor: pointer;
  }

  .link-icon {
    font-size: calc(#{$f-9} + 2vmin);
  }

  .link-label {
    font-size: calc(#{$f-6} + 2vmin);
    padding-left: 1.5em;
  }
}

.header-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    min-width: 2rem;
    max-width: 43vw;
    height: 100%;
    margin: auto;

    img{
      height: auto;
      width: auto;
      min-height: 3rem;
      max-height: 5rem;
      max-width: 100%;
    }
}

.progress-container {
	width: 100%;
	justify-content: space-between;
}
@media screen and (min-width: 641px) {
  .link-label {
    padding-left: 2.4em !important;}
}
@media screen and (min-width: 1024px) {
	.link-label {
  font-size: calc(#{$f-05} + 2vmin) !important;
  padding-left: 1.9em !important;
	}

}
