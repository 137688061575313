/*------ This file is for utiliy classes, mixins, and other helpers -----*/


// These 3 mixins are for quickly identifying styling bugs

@mixin debug {
  outline: 1px dotted red !important;
  background-color: rgba(red, 0.3) !important;
}

@mixin debug-compare {
  outline: 1px dotted blue !important;
  background-color: rgba(blue, 0.3) !important;
}

@mixin debug-focus {
  outline: 1px dotted orange !important;
  background-color: rgba(yellow, 0.9) !important;
}

//--------------------------------------------------------


//This mixin is for using a fontawesome icon in a :before or :after psuedoselector, in cases where a Simple Line Icon is unavailable.

@mixin fontawesome {
     display: inline-block;
     font-style: normal;
     font-variant: normal;
     text-rendering: auto;
     -webkit-font-smoothing: antialiased;
     font-family: "Font Awesome 5 Free";
     font-weight: 900;
}

//--------------------------------------------------------
