@import '../../sass/global/variables';

$kpi-grey: #666666;

#progress-card-container{

    border-radius: 16px;
    margin: 0.5rem;
    padding: 1.2em;
    display: flex;
    flex-direction: column;

.progress-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $kpi-bg-color;
  max-width: 300px;
  margin-bottom: 1.5em;

  h2 {
    flex: 1 1 auto;
    color: $white-color;
    font-size: 2.4em;
    font-weight: normal;
    font-family: $roboto;
  }

  &_summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //font-size: 1.7em;
    font-size: calc(1.3rem + 1vmin);
    border: 1px solid $kpi-grey;
    border-radius: 3px;
    padding: 0.2em;
    margin-left: 0.3rem;
    height: 100%;
    align-self: stretch;

  }

}

}


//============================//
//=====Progress Structure=====//
//=== No styling goes here ===//
//============================//

#progress-card-container {
display: grid;
grid-template-columns: 1fr 1fr 1fr 5%;
grid-template-rows: repeat(3, auto);
grid-column-gap: 1em;
grid-row-gap: 5px;
grid-template-areas:
    "header header header spacer"
    "thisyear thisyear thisyear spacer"
    "lastyear lastyear lastyear spacer";


.racing-stripe {
    grid-area: 1 / 1 / 4 / 5;
  }

.progress-header {
  grid-area: header;
}

.thisyear {
  grid-area: thisyear;
}

.lastyear {
  grid-area: lastyear;
}

}





//===========================//



@media (min-width: 1024px) {
  #progress-card-container{
    //height:40vh !important;
  .progress-header h2 {
    font-size: 3em !important;
  }
  .kpiprogress-wrapper{
    width: 95% !important;
  }
    }
}


@media (max-width: 360px){

  .progress-header h2 {
    font-size: 2em !important;
  }

  }
  