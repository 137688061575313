#chart-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #282C34;
    border-radius: 16px;
    // height: 100%;

    svg.speedometer {
        padding-top: 70px;
    }
}