.preloader {
    z-index: 1000; 
    position: absolute;
    left: 45%;
    top: 50%;
}
.kpiselect-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    top:8em;
    width: 100%;
    left: -1em;
    z-index: 1000;
}
@media screen and (min-width: 641px) {
    .kpiselect-container{
        top: 10em;
    }
}
@media screen and (min-width: 1024px) {
    .kpiselect-container{
        top: 8em;
    }
}
