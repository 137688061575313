// Color Palette

$filler: #fff;

$black: #111111;
$offblack: #1d1d1d;
$black-opacity90: rgba($black, 0.9);

$white: #fefefe;

$ws-darkgrey: rgba(51, 51, 51,1);



/*---- brand colors -----*/

$amsgreen: #284E38;

/*---- material colors -----*/

$green50: #E8F5E9; //lightest
$green100: #C8E6C9;
$green200: #A5D6A7;
$green300: #81C784;
$green400: #66BB6A;
$green500: #4CAF50;
$green600: #43A047;
$green700: #388E3C;
$green800: #2E7D32;
$green900: #1B5E20; //darkest

$bright-green100: #B9F6CA;
$bright-green200: #69F0AE;
$bright-green400: #00E676;
$bright-green700: #00C853;


$grey50: #FAFAFA; //lightest
$grey100: #F5F5F5;
$grey200: #EEEEEE;
$grey300: #E0E0E0;
$grey400: #BDBDBD;
$grey500: #9E9E9E;
$grey600: #757575;
$grey700: #616161;
$grey800: #424242;
$grey900: #212121; //darkest


// colors


$brightblue: #00ffff;
$softblue: #CCFFFF;


//websafe colors


$ws-light-blue: rgba(204, 255, 255,1); //#CCFFFF
$ws-purple: rgba(153, 102, 255,1 ); //#9966FF
$ws-cyan: rgba(102, 255, 255,1); //#66FFFF
$ws-offblack: rgba(51, 51, 51,1); //#333333




// Sizing & Spacing

$padding-large: 30px;
$padding-medium: 20px;
$padding-small: 10px;
$padding-extra-small: 5px;

$margin-large: 30px;
$margin-medium: 20px;
$margin-small: 10px;
$margin-extra-small: 5px;


$navheight: 20%;




// Typography

// font options
$anton: 'Anton', sans-serif;
$fugaz: 'Fugaz One', cursive;
$chewy: 'Chewy', cursive;
$leckerli: 'Leckerli One', cursive;
$oleo: 'Oleo Script', cursive;
$bungee: 'Bungee', cursive;
$oleo-swash: 'Oleo Script Swash Caps', cursive;
$bungee-hairline: 'Bungee Hairline', cursive;
$bungee-outline: 'Bungee Outline', cursive;
$bungee-shade: 'Bungee Shade', cursive;
$roboto: 'Roboto', sans-serif;
$heebo: 'Heebo', sans-serif;





// Shadows


$text-shadow: 1px 1px 1px #919191,
        1px 2px 1px #919191,
        1px 3px 1px #919191,
        1px 4px 1px #919191,
        1px 5px 1px #919191,
        1px 6px 1px #919191,
        1px 7px 1px #919191,
        1px 8px 1px #919191,
        1px 9px 1px #919191,
        1px 10px 1px #919191,
    1px 18px 6px rgba(16,16,16,0.4),
    1px 22px 10px rgba(16,16,16,0.2),
    1px 25px 35px rgba(16,16,16,0.2),
    1px 30px 60px rgba(16,16,16,0.4);

   
$standard-shadow: 0px 10px 16px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.06);

$elevated-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04);



//===== project variables ======
//==============================

$primary-color:  #53B99C;
$disabled-color: #DDDDDD;
$normal-color: #F3EFE4;
$background-color: #FDFDFD;
$text-color: #515151;
$icon-color: #151515 !default;
$black-color: #000000;
$white-color: #ffffff;
$seperator-color: 	#D8D8D8;
$price-span: #417505;
$progress-color: #53B99C;
$movesize-bgcolor: #F3F3F3;
$bedsize-bgcolor:  #FDFDFD;
$kpi-bg-color: #1A1819;

$main-font-family: 'Roboto', sans-serif;
$secondary-font-family:  'Heebo', sans-serif;
$dinpro-font-family:  'DINPro';

$ams-brand-color: #284E38;


$f-01: 0.01rem;
$f-02: 0.02rem;
$f-03: 0.03rem;
$f-04: 0.04rem;
$f-05: 0.05rem;
$f-06: 0.06rem;
$f-07: 0.07rem;
$f-08: 0.08rem;
$f-09: 0.09rem;
$f-1: 0.1rem;
$f-2: 0.2rem;
$f-3: 0.3rem;
$f-4: 0.4rem;
$f-5: 0.5rem;
$f-6: 0.6rem;
$f-7: 0.7rem;
$f-8: 0.8rem;
$f-9: 0.9rem;
$f-10: 1rem;  // 10px
$f-11: 1.1rem; // 11px
$f-12: 1.2rem;
$f-13: 1.3rem;
$f-14: 1.4rem;
$f-15: 1.5rem;
$f-16: 1.6rem;
$f-17: 1.7rem;
$f-18: 1.8rem;
$f-19: 1.9rem;
$f-20: 2.0rem;
$f-21: 2.1rem;
$f-22: 2.2rem;
$f-23: 2.3rem;
$f-24: 2.4rem;
$f-25: 2.5rem;

$font-size-subtitle: calc(#{$f-9} + 2vmin);
$font-size-title: calc(#{$f-17} + 2vmin);

$thin: 100;
$ultra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$heavy: 800;
$black: 900;

$uppercase: uppercase;

$break-small: 320px;
$break-medium: 375px;
$break-large: 414px;
$break-minipad: 768px;
$break-maxipad: 1024px;
$break-desktop: 1200px;



$white-color: #ffffff;
$color-gray-bg: #F3F3F3;
$color-gray-light: #FAFAFA;
$color-tan-bg: #F3EFE4;
$primary: #00945D;
$color-green-neon: #7ED321;
$color-green-dark: #417505;
$color-green-progress: #53B99C;
$color-links:#000;
$yellow: #f8cb35;
$blue: #2A9FD8;
$active-bg:rgba(0,0,0,0.1);
$affirm-blue: #0FA0EA;

//navigation variables
$navigation-bg-color: #16644e;
$navigation-active-bg: #53b99c;

//button variables
$btn-primary-bg: $primary;
$btn-secondary-bg: #233d4d;
$btn-primary-color: $white-color;
$btn-hover-color: $color-green-progress;
$btn-focus-color: $color-green-progress;
$btn-active-color: $color-green-progress;

//price variables
$circle-bg: #d8d8d8;
$price-address-bg: #eaeaea;

//border variables
$border-grey-color: grey;
$border-lt-grey-color: #e0e0e0;

//padding variables
$padding-large: 30px;
$padding-medium: 20px;
$padding-small: 10px;
$padding-extra-small: 5px;

$btn-border-radius: 99999px;

$font-family-sans-serif: 'Roboto', sans-serif;
$font-family-title: 'Heebo', sans-serif;

//text style variables
$text-transform: uppercase;



// list group variables

$border-radius: 0px;
$list-group-item-padding-y: 0.2em;
$list-group-item-padding-x: 0.75em;

// height variables
$navbar-logo-height: 3.35rem;

$progress-height: .5rem;

//Misc. variables

$ease-bounce: cubic-bezier(.75, .1, .1, 1.25);
$box-shadow: 0px 1px 3px rgba(10,10,10,0.2), 0px 1px 5px rgba(10,10,10,0.1);




$sans-serif: $roboto;
$heading: $heebo;
$subheading: $roboto;

$base-font: $sans-serif, Helvetica, Arial, -apple-system, BlinkMacSystemFont, sans-serif;







