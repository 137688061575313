@import 'src/theme/styles/variables';


/*---- Brand Adverb Styling -----*/

#animation {

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  width: 100%;
  margin: auto;
  position: relative;
  overflow: visible;
}

.adverb-inner{

  margin: 0.7em 0 3em;
  width: auto;
  height: 5em;
  overflow: visible;
}


.adverb {
  position: relative;
  overflow: visible;
  justify-content: center;
  margin: 1em;
  padding: 1em;
  width: 100%;
  margin-left: 0em;

  perspective: 10em;
  backface-visibility: hidden;
  transition: all 80ms ease-in-out;
  transition-property: opacity;
  transform: none;

  display: none;
  z-index: 0;
  opacity: 0;

            svg path{
                fill: $color-tan-bg;
            }

            svg {
              height: 3em;
              height: calc(#{2rem} + 3vmin);
              overflow: visible;
              padding: 0.2rem;
              }
        }

.adverb.active {

  display: flex;
  z-index: 1;
  opacity: 1;
  overflow: visible;
  animation: grow 1.9s 2;
  animation-timing-function:cubic-bezier(.75, .1, .1, 1.25);
  animation-direction: alternate;
  animation-fill-mode: forwards;

    svg {
      overflow: visible;
      animation: turn 5s 1;
      animation-timing-function:cubic-bezier(.75, .1, .1, 1.25);
      animation-direction: normal;
      animation-fill-mode: forwards;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      }

    svg g { //dumb fix for Chrome's treatment of transform scaled SVGs. Should reduce blurriness in Chrome.
       transition: transform 1s linear;
       transform-origin: 0 0;
        }

    svg path{
       fill: $primary;
       animation: curve 4s 1;
       animation-timing-function:cubic-bezier(.25, .1, .1, 1);
       animation-direction: normal;
       animation-fill-mode: forwards;
        }
}

.adverb.static {
  display: flex;
  z-index: 1;
  opacity: 1;
  overflow: visible;

  svg {
   overflow: visible;
    }

  svg path{
     fill: $primary;
      }
}


/*----The following animations occur at the same time: grow, curve, turn ---*/

//------------------------------------------

@keyframes grow { //The word zooms in

0% {

-webkit-transform: scale(0); /* Safari */
transform: scale(0);
opacity: 0;
}


50% {

  -webkit-transform: scale(1.2);
   transform: scale(1.2);
   opacity: 1;

    }



100% {


-webkit-transform:  scale(1.2);
transform:  scale(1.2);

}
}

//------------------------------------------

@keyframes curve { //The word eases up slightly, then down

0% {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

}


10% {

-webkit-transform:  translate3d(0, -.5em, 0);
transform:  translate3d(0, -.5em, 0);


}

40% {

-webkit-transform:  translate3d(0, .2em, 0);
transform:  translate3d(0, .2em, 0);
}


70% {

-webkit-transform:  translate3d(0, .1em, 0);
transform:  translate3d(0, .1em, 0);
}

75% {

-webkit-transform:  translate3d(0, .6em, 0);
transform:  translate3d(0, .6em, 0);
}
98% {

-webkit-transform:  translate3d(0, -.3em, 0);
transform:  translate3d(0, -.3em, 0);

}

100% {

  -webkit-transform:  translate3d(0, -.6em, 0);
  transform:  translate3d(0, -.6em, 0);
}
}

//------------------------------------------

@keyframes turn { //The word leans in and then out
0% { transform: rotateX(5deg); }
30% { transform: rotateX(-0.1deg); }

87% { transform: rotateX(-3deg); }
100% { transform: rotateX(-7deg); }
}


/* -------------------------------------------------------- */
