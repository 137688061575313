@import '../../theme/styles/variables';

#login-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #F3EFE4;
	padding-bottom: 50px;
	height: 100vh;
	.login-header{
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		.signin-logo {
			display: flex;
			padding-top: 0;
		}
		.close-icon{
			position: relative;
			right: 12px;
    		top: -54px;

		}

	}
    .login-info{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: rgb(206, 82, 109);
		padding-top: 3em;
		font-size: 1.7em;
	}
	.text-field {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		padding-top: 18%;
	}
	.login-button {
		width: 100%;
		padding-top: 6%;
		font-family: $main-font-family;
	}
	.line-view {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding-top: 4%;
		width: 95%;
		.social-seperator {
			height: 2px;
			width: 18.67%;
			opacity: 0.5;
			background-color: #B1B1B1;
		}
		.info-text {
			padding-left: 10px;
			padding-right: 10px;
			padding-top: 9px;
			p {
				font-size: calc(#{$f-13} + 2vmin);
				color: #909090;
				font-family: $main-font-family;
				line-height: 14px;
				text-align: center;
				text-transform: uppercase;
			}
		}
	}
	.social-btn {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 83%;
		font-family: $main-font-family;
		.MuiButton-label {
			padding-left: 25px !important;
		}
		.google {
			position: absolute;
			left: 57px;
			padding-top: 6px;
			z-index: 100;
			color: #ffffff;
			font-size: 3rem;
		}
		.facebook {
			position: absolute;
			right: 140px;
			padding-top: 8px;
			z-index: 100;
			color: #ffffff;
			font-size: 2.3rem;
		}
	}
	.signup-info {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding-top: 40%;
	}
	p {
		// font-size: calc(#{$f-6} + 2vmin);
		color: #909090;
		cursor: pointer;
	}
	.signup-text {
		font-size: calc(#{$f-6} + 2vmin);
		color: #232B41;
		font-weight: $bold;
		text-decoration: underline;
		text-transform: uppercase;
	}

	.MuiButton-label {}

	.MuiTypography-body1 {
		font-size: 2rem !important;
	}
	.MuiInputBase-input:focus {
		font-size: calc(1.2rem + 2vmin) !important;
	}
	.MuiInputBase-input {
		font-size: calc(1.2rem + 2vmin) !important;
	}
	.MuiFormHelperText-root.Mui-error {
		font-size: 1.6em !important;
	}
	.text-field {
		form {
			background-color: #F3EFE4 !important;
		}
	}
	.MuiInputLabel-root {
		font-size: calc(#{$f-10} + 2vmin) !important;
		color: #454545 !important;
		font-style: normal !important;
		font-family: $main-font-family;
		font-weight: $light;
	}
	.MuiInputLabel-shrink {
		-webkit-transform: translate(0, 1.5px) scale(1) !important;
		transform: translate(0, 1.5px) scale(1) !important;
	}
}

.login-background {
	background-color: #F3EFE4;
}

@media screen and (max-width: 320px) {
	#login-container {
		.facebook {
			left: 166px !important;
		}
		.google {
			left: 35px !important;
		}
		.MuiButton-root {
			padding-left: 27px !important;
			padding-top: 2px !important;
		}
	}
}

@media screen and (min-width: 1024px) {
	#login-container {
		max-width: 450px;
		margin: 0 auto;
		background-color: #F3EFE4;
		.MuiInputLabel-root {
			font-size: calc(#{$f-6} + 2vmin) !important;
		}
		.signup-text {
			font-size: calc(#{$f-3} + 2vmin)!important;
		}
		.signup-info {
		
		p {
			font-size: calc(#{$f-3} + 2vmin)!important;
		}
	}
		.info-text {
			p {
				font-size: calc(#{$f-3} + 2vmin) !important;
			}
		}
		.makeStyles-textField-2 {}
		.text-field {
			form {
				background-color: #F3EFE4 !important;
				width: 29vw !important;
			}
		}
		.google {
			left: 477px !important;
			padding-top: 35px !important;
		}
		.facebook {
			right: 596px !important;
			padding-top: 32px !important;
		}
		.close-icon {
			right: 12px !important;
			top: 21px !important;
		}
		.signin-logo {
			padding-top: 22.09% !important;
		}
	}
}