@import '../../../sass/global/variables';

$kpi-grey: #666666;
$kpi-number-cells: '[data-column="yoy"]:not([data-row="heading"]),[data-column="target"]:not([data-row="heading"])';


.kpi-table {

  color: $white-color;
  margin: 0.5rem;
  padding: 1.2em 0.7em 1.2em 1.2em;
  

  &_title {
    font-weight: bold;
   

    h2{
      display: inline-block;
      padding-right: 0.3em;
      background: $kpi-bg-color;
      font-size: 2.4em;
      white-space: nowrap;
      font-weight: bold;
      font-family: $roboto;
    }
  }

  &_heading-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $kpi-bg-color;
    padding: 0.3rem 3px 3px;
  }

  .kpi-label{
    font-size: 1.8em;
    display: flex;
    align-items: center;
    font-weight: normal;
    font-family: $roboto;

  }



}


#{$kpi-number-cells} {

  padding: 0 3vmin;
  display: flex;
  justify-content: space-between;
  align-items: center;


  span {
    text-align: right;
    font-size: calc(2rem + 1vmin);

    &:after {
    content: "%";

  }
}
}






//==========================//
//=====Table Decorations====//
//=========================//


.racing-stripe {
  box-shadow: inset -5px 5px 1px $kpi-grey;
  border-radius: 0 20px 0 0;
  margin-top: 1rem;
}

.column-border {
border: 2px solid $kpi-grey;
border-radius: 3px;
box-shadow:
  0px 0px 0 3px $kpi-bg-color,
  0px 0px 0 3px $kpi-bg-color;

}


//============================//
//====Table Cell Structure====//
//=== No styling goes here ===//
//============================//

[data-table="kpi"] {
display: grid;
grid-template-columns: 1fr 1fr 1fr 2%;
grid-column-gap: 1em;
grid-row-gap: 10px;

}

[data-row="heading"] {

  &[data-column="label"] {
   grid-area: head-label; // 1 / 1 / 2 / 2;
  }

 &[data-column="yoy"] {
    grid-area: head-yoy; // 1 / 2 / 2 / 3;
 }

  &[data-column="target"] {
    grid-area: head-target; // 1 / 3 / 2 / 4;
  }

}

//===========================//



@media (min-width: 1024px) {
  #{$kpi-number-cells} {
      span {
        font-size: 2em !important;
        //padding: 0 5vmin !important;
      }
    }
    .kpi-label{
        font-size: 1.7em !important;
      }
}

@media (min-width: 350px){
.kpi-table .kpi-label{
  //white-space: nowrap !important;
}
}


@media (min-width: 730px) {
  [data-column]:not([data-column="label"]), .column-border { 
    max-width: 12em;
    
    }
}

@media (max-width: 360px){

  #{$kpi-number-cells} {

    span{
      font-size: calc(1.3rem + 1vmin);
    }

  }

  .kpi-label{
    font-size: 1.5em !important;
  }

  .kpi-table_title h2 {
    font-size: 2em;
  }

  }
  
