@import 'src/theme/styles/variables';

.MuiInput-underline.Mui-error:after {
	border-bottom: 1px solid #CE526D !important;
}
.MuiFormLabel-root.Mui-error {
	color: #000000 !important;
	font-size: calc(#{$f-7} + 2vmin) !important;
}

.MuiInput-underline:after {
	border-bottom: 1px solid #388e3c !important;
}

.MuiInputLabel-root {
	font-size: calc(#{$f-7} + 2vmin) !important;
	color: #212529 !important;
}
.MuiInputBase-input {
	color: #000000 !important;
	font-size: calc(#{$f-6} + 2vmin) !important;
	font-family: Roboto !important;
	//font-style: italic !important;
	font-weight: 300 !important;
	line-height: 16px !important;
}

.MuiInputBase-input:focus {
	color: #212529 !important;
	font-size: calc(#{$f-6} + 2vmin) !important;
}

.makeStyles-container-61 {
	border: none !important;
}
.MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(0.85) !important;
}

@media screen and (min-width: 1024px) {
	.MuiInputLabel-root {
		font-size: calc(#{$f-01} + 2vmin) !important;
		color: #212529 !important;
	}
	.MuiInputBase-input {
		font-size: calc(#{$f-05} + 2vmin) !important;
		line-height: 16px !important;
	}
	.MuiInputBase-input:focus {
		color: #212529 !important;
		font-size: calc(#{$f-05} + 2vmin) !important;
	}
	.MuiFormLabel-root.Mui-error {
		font-size: calc(#{$f-01} + 2vmin) !important;
	}
}