@import 'src/theme/styles/variables';

.container-fluid.force-shrink {
	height: auto;
	display: flex;
	flex-direction: column;
	padding-right: 2px !important;
	padding-left: 3px !important;
	-webkit-overflow-scrolling: touch;
}

.row.force-shrunk {
	flex-shrink: 1;
	overflow: auto;
	padding-bottom: 45px;
}

.DayPicker {
	font-size: 1.8em;
	margin: 0 auto;
	background: #F3F3F3;
	padding-top: .5em;
	display: block;
	&-wrapper {}
	&-Months {
		width: 100%;
		background: #ffffff;
		max-width: 40em;
		margin: auto;
		padding-bottom: 1em;
	}
	&-Month {
		width: 90%;
		max-width: 40em;
	}
	&-NavBar {
		position: absolute;
		padding-top: 10px;
		width: 100%;
		button {
			background: none !important;
			color: #fff;
			border: none;
		}
	}
	&-Weekday {
		padding: .25em;
		color: #000;
	}
	&-Caption {
		text-align: center !important;
		color: #000;
	}
	&-Day {
		color: #00945D;
		width: 14.29%;
		background: #FAFAFA;
		border: solid #ffffff 3px;
		font-size: 1em;
		border-radius: 0;
		padding: 1em 0;
		&--disabled,
		&--outside {
			font-size: 0px;
			background: #FFF;
		}
	}
}

.DayPicker-Caption>div {
	font-size: 0.95em !important;
}

.list-group-notsure {
	display: flex;
	flex-flow: row nowrap;
	align-content: center;
	align-items: center;
	justify-content: space-around;
	padding: 30px 10px;
	&:active {
		background-color: #F3EFE4;
	}
	.txt {
		flex: 1 1 auto;
		order: 1;
		font-size: 1.3em;
		padding-left: 0.75em;
	}
	.right-arrow {
		order: 2 !important;
		svg {
			height: 2rem;
			width: 2rem;
		}
	}
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
	background: #22e9b2 !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
	background-color: $primary-color;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
	background-color: #4EE9B1;
	color: #000;
}

@media (min-width: 1024px) {
	.DayPicker-NavButton {
		right: 15.5em;
	}
}

.DayPicker-wrapper {
	position: relative;
	flex-direction: row;
	padding-bottom: 0 !important;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
